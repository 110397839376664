import React, { useEffect, useState } from 'react'
import authService from '../Services/auth.service';

const CategoryMenus = (props) => {
 
    return (
        <>
            <section className="mt-5">
                <div className="container">
                    {/* <div className="table-responsive">
                        <table className="table text-white">
                            <tbody>
                                <tr>
                                    {testEx.map((trade, index) => (
                                        <td key={index}>
                                            <span>{trade}</span>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                    <div className="table-responsive">
                        <div className='d-flex align-items-start text-white'>
                            {props.categoryData?.length > 0 && props.categoryData?.map((iteam, index) => (
                                <div className={`${index !== 0 ? "px-5" : "px-2"}`} key={index}>
                                    <span className='text-uppercase'>{iteam.category_name}</span>
                                </div>
                            ))}
                        </div>
                        <hr className='text-white' />
                    </div>
                </div>
            </section >
        </>
    )
}

export default CategoryMenus