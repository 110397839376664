import React from 'react'
import { CiFacebook } from 'react-icons/ci'
import { FcGoogle } from "react-icons/fc";
import { AiFillApple } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import authService from '../Services/auth.service';
import SignIn from './SignIn';
import swal from 'sweetalert';
import Select from 'react-select';





const customStyles = {
    input: (provided) => ({
        ...provided,
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 16
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#2C133B',
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 16
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 16
    }), menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#5C245C',
        fontFamily: 'Roboto',
        fontSize: 16
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: 'white',
        fontSize: 16
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#B186D2' : '#3A1740',
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 15
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition, color: 'white' };
    }
};

const JoinMoonetized = () => {
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const [showSignInModal, setshowSignInModal] = useState(false);
    const [showSignupModal, setshowSignUpModal] = useState(true);
    const [countries, setCountries] = useState([]);


    const countriesOptions = countries.map((iteam) => {
        return { value: iteam.NAME, label: iteam.NAME }
    })

    const [registrationFormInputes, setregistrationFormInputes] = useState({
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        country: ""
    })
    const [errorRegistrationForm, seterrorRegistrationFom] = useState({
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        country: ""
    })



    const handleRegsitartionFormInputes = (e) => {
        const { name, value } = e.target
        setregistrationFormInputes({ ...registrationFormInputes, [name]: value })
        switch (name) {
            case 'username':
                errorRegistrationForm.username = value.length > 0 ? "" : "Enter username";
                break;
            case 'password':
                if (!value) {
                    errorRegistrationForm.password = "Please enter Password";
                }
                else if (value.length < 8) {
                    errorRegistrationForm.password = "Password length must more than 8 character";

                }
                else if (value.length > 255) {
                    errorRegistrationForm.password = "Password length must be less than 255 characters";
                }
                else if (value !== registrationFormInputes.confirmPassword) {
                    errorRegistrationForm.password = "Password and Confirm Password does not match.";
                }
                else {
                    errorRegistrationForm.password = ""
                    errorRegistrationForm.confirmPassword = "";
                }
                break;
            case 'confirmPassword':
                if (!value) {
                    errorRegistrationForm.confirmPassword = "Please enter Confirm Password ";
                }
                else if (value.length < 8) {
                    errorRegistrationForm.confirmPassword = "Password length must more than 8 character";

                }
                else if (value.length > 255) {
                    errorRegistrationForm.confirmPassword = "Password length must be less than 255 characters";
                }
                else if (value !== registrationFormInputes.password) {
                    errorRegistrationForm.confirmPassword = "Password and Confirm Password does not match.";
                }
                else {
                    errorRegistrationForm.confirmPassword = ""
                    errorRegistrationForm.password = "";
                }
                break;
        }
        seterrorRegistrationFom(errorRegistrationForm)
    }

    const validate = () => {
        if (!registrationFormInputes.username) {
            errorRegistrationForm.username = "Enter username";
        }
        if (!registrationFormInputes.password) {
            errorRegistrationForm.password = "Enter password";
        }
        if (!registrationFormInputes.confirmPassword) {
            errorRegistrationForm.confirmPassword = "Enter confirm password";
        }

        return errorRegistrationForm;
    };


    useEffect(() => {
        if (Object.keys(errorRegistrationForm).length === 0 && isSubmit) {
            console.log(registrationFormInputes);
        }
        authService.getCountries().then((response) => {
            setCountries(response.data);
        });
    }, []);

    const handleUserSignUp = (e) => {
        e.preventDefault();
        seterrorRegistrationFom(validate(registrationFormInputes));
        setIsSubmit(true);
        setshowSignInModal(true);

        if (registrationFormInputes.username !== "" && registrationFormInputes.password !== "" && registrationFormInputes.confirmPassword !== "") {
            if (registrationFormInputes.password === registrationFormInputes.confirmPassword) {
                var data = {
                    username: registrationFormInputes.username,
                    email: registrationFormInputes.email,
                    password: registrationFormInputes.password,
                    password_confirmation: registrationFormInputes.confirmPassword,
                    country: registrationFormInputes.country
                }
                authService.userSignUp(data).then((response) => {


                    console.log(response);
                    if (response.data.status === "success") {
                        console.log("success")
                        swal({
                            title: "Success",
                            text: "Your have successfully joined Moonetized. You can now login from Home Page",
                            icon: "success",
                            button: "Login",
                            type: 'success',
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                            allowOutsideClick: false,
                        })
                            .then((value) => {
                                if (value) {

                                    window.location.reload();
                                }
                            });;

                    }
                    else if (response.data.status === 400) {
                        swal("Success", response.data.message, "success");
                    }
                }).catch((e) => {
                    console.log(e.response.data.message)

                    swal("Oops!", e.response.data.message, "error");
                })
            }
        }
    }

    const handleRegsitartionFormInputesCountry = (selectedOption) => {
        if (selectedOption) {
            const { value } = selectedOption;
            setregistrationFormInputes({ ...registrationFormInputes, country: value });
        }
    };

    return (
        <>
            <div>
                {showSignupModal && (
                    <div class="modal fade " id="joinNowModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

                        <div class="modal-dialog">
                            <div class="modal-content cst-bk">
                                <div class="modal-header">
                                    <a href='#' className='m-auto float-end'> </a><IoMdClose className='close-icons' data-bs-dismiss="modal" aria-label="Close" />
                                </div>
                                <div class="modal-body mx-3">
                                    <h3 className='text-center mb-5 text-uppercase'> join moonetized</h3>
                                    {/* <div className='row p-2 px-3 ' >
                                <button className='btb btn-facebook-btn p-2'><CiFacebook className='mx-3' />Continue with Facebook</button>
                            </div>
                            <div className='row p-2 px-3 ' >
                                <button className='btb btn-facebook-btn p-2'><FcGoogle className='mx-3' />Continue with Google</button>
                            </div>
                            <div className='row p-2 px-3 ' >
                                <button className='btb btn-apple-btn p-2'><AiFillApple className='mx-3' />Continue with Apple</button>
                            </div>

                            <div class="d-flex justify-content-between align-items-center">
                                <hr class="w-50" />
                                <span class="mx-3">OR</span>
                                <hr class="w-50" />
                            </div> */}
                                    <form onSubmit={handleUserSignUp}>
                                        <div className='row p-2 px-3 ' >
                                            <input className="col-12 form-control clas-cat text-right pt-2" name="username" placeholder='Username' onChange={handleRegsitartionFormInputes} />
                                        </div>
                                        <span className="red-error" style={{ "color": "red" }}  >{errorRegistrationForm.username}  </span>
                                        <div className='row p-2 px-3 ' >
                                            <input className="col-12 form-control clas-cat text-right pt-2" type="email" name="email" placeholder='Email' onChange={handleRegsitartionFormInputes} />
                                        </div>
                                        <span className="red-error" style={{ "color": "red" }}  >{errorRegistrationForm.email}  </span>
                                        <div className='row'>
                                            <Select
                                                className="multiselect-cst"
                                                styles={customStyles}
                                                options={countriesOptions}
                                                placeholder="Select"
                                                isClearable={true}
                                                name='country'
                                                onChange={handleRegsitartionFormInputesCountry}

                                            />
                                        </div>
                                        <span className="red-error" style={{ "color": "red" }}  >{errorRegistrationForm.country}  </span>
                                        <div className='row p-2 px-3 ' >
                                            <input class="col-12 form-control clas-cat text-right pt-2" type="password" placeholder='Password' name='password' onChange={handleRegsitartionFormInputes} />
                                        </div>
                                        <span className="red-error" style={{ "color": "red" }}>{errorRegistrationForm.password}  </span>
                                        <div className='row p-2 px-3 ' >
                                            <input class="col-12 form-control clas-cat text-right pt-2" type="password" placeholder='ConfirmPassword' name='confirmPassword' onChange={handleRegsitartionFormInputes} />
                                        </div>
                                        <span className="red-error" style={{ "color": "red" }}>{errorRegistrationForm.confirmPassword}  </span>

                                        <div className='row mt-4 mb-2 p-3' >
                                            <button className='btn btn-green-cst p-2' type='submit' >Continue</button>
                                        </div>
                                    </form>
                                    <p className='join-moonetized-model text-center'>By joining i agree to receive emails from Moonetized</p>

                                    <hr className='mt-5' />
                                    <p className='n-remember-yet text-center'>Already a member? <a data-bs-toggle="modal" className='join-now-a text-decoration-underline' data-bs-target="#signinModal" >Sign In</a></p>
                                </div>
                            </div>
                        </div>

                    </div>
                )}
                {/* {showSignInModal && (<SignIn/>)} */}
            </div>
        </>
    )
}

export default JoinMoonetized