import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SellerDashabord from './Pages/SellerDashabord';
import CreateNewProduct from './Pages/CreateNewProduct';
import EditProfileSetting from './models/EditProfileSetting';
import SignIn from './models/SignIn';
import JoinMoonetized from './models/JoinMoonetized';
import LandingPage from './Pages/LandingPage';
import Home from './Pages/Home';
import SingleProductPage from './Pages/SingleProductPage';
import EditProduct from './Pages/EditProduct';
import PageNotFound from './Pages/PageNotFound';
import './App.css';

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route exact path='/' element={<LandingPage />} />
          <Route path='/home' element={<Home />} />
          <Route path='/seller-dashaboard' element={<SellerDashabord />} />
          <Route path='/single-product/:id' element={<SingleProductPage />} />
          <Route path='/add-new-product' element={<CreateNewProduct />} />
          <Route path="/edit-product/:id" element={<EditProduct />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <SignIn />
        <JoinMoonetized />
        <EditProfileSetting />
      </Router>
    </>
  );
}

export default App;