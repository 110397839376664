import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import authService from '../Services/auth.service';
import swal from 'sweetalert';
import { IoMdClose } from "react-icons/io";
import Loader from '../components/Loader';

const CreateNewProduct = () => {
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const [showSignInModal, setshowSignInModal] = useState(false);
    const [showSignupModal, setshowSignUpModal] = useState(true);
    const [categories, setCategories] = useState(true);
    const [subCategories, setSubCategories] = useState(true);
    const [CLU, setCLU] = useState(true);
    const [sfm, setSFM] = useState(true);
    const [usd, setUSD] = useState("");
    const [CLUValue, setCLUValue] = useState("");
    const [sfmValue, setSFMValue] = useState("");
    const [usdValue, setUSDValue] = useState("");
    const [addedCryptos, setAddedCryptos] = useState([]);
    const [cryptos, setCryptos] = useState([]);
    const [productImage, setProductImage] = useState(null);
    const [flag, setFlag] = useState(false)




    //User Details
    const userDetails = JSON.parse(localStorage.getItem('userData'))
    const client_id = userDetails?.data?.clientID;

    const [creationProductsInputs, setcreationProductsInputs] = useState({
        title: "",
        product_category: "",
        sub_category: "",
        positive_keyword: "",
        description: "",
        price: "",
        product_url: "",
        product_image: "",
        video_link: ""
    });

    const [errorProductCreationForm, setErrorProductCreationForm] = useState({
        title: "",
        product_category: "",
        sub_category: "",
        positive_keyword: "",
        description: "",
        price: "",
        product_url: "",
        product_image: "",
        video_link: ""
    })

    const handleImageChange = (e) => {
        setProductImage(e.target.files[0]);
        setcreationProductsInputs({ ...creationProductsInputs, [e.target.name]: e.target.files[0] })

        handleProductFormInputs(e);
    }

    const handleProductFormInputs = (e) => {

        const { name, value } = e.target
        setcreationProductsInputs({ ...creationProductsInputs, [name]: value })
        switch (name) {
            case 'title':
                errorProductCreationForm.title = value.length > 0 ? "" : "Enter title";
                break;
            case 'product_category':
                errorProductCreationForm.product_category = value.length > 0 ? "" : "Choose Category";
                break;
            case 'sub_category':
                errorProductCreationForm.sub_category = value.length > 0 ? "" : "Choose Sub Category";
                break;
            case 'description':
                errorProductCreationForm.description = value.length > 0 ? "" : "Enter description";
                break;
            case 'price':
                errorProductCreationForm.price = value.length > 0 ? "" : "Enter Price";
                break;
            // case 'product_url':
            //     errorProductCreationForm.product_url = value.length > 0 ? "" : "Enter Product URL";
            //     break; 
            case 'product_image':
                errorProductCreationForm.product_image = value.length > 0 ? "" : "Add An Image for Your Product";
                break;

        }
        setErrorProductCreationForm(errorProductCreationForm)
    }
    console.log(creationProductsInputs, "creationProductsInputs")
    const validate = () => {
        if (!creationProductsInputs.title) {
            errorProductCreationForm.title = "Enter title";
        }
        if (!creationProductsInputs.product_category) {
            errorProductCreationForm.product_category = "Choose Category";
        }
        if (!creationProductsInputs.sub_category) {
            errorProductCreationForm.sub_category = "Choose Sub-Category";
        }
        if (!creationProductsInputs.description) {
            errorProductCreationForm.description = "Enter Description";
        }
        if (!creationProductsInputs.price) {
            errorProductCreationForm.price = "Enter Price";
        }
        // if (!creationProductsInputs.product_url) {
        //     errorProductCreationForm.product_url = "Enter Product URL";
        // }
        if (!creationProductsInputs.product_image) {
            errorProductCreationForm.product_image = "Add An Image for Your Product";
        }


        return errorProductCreationForm;
    };


    useEffect(() => {
        if (Object.keys(errorProductCreationForm).length === 0 && isSubmit) {
            console.log(creationProductsInputs);
        };
        authService.getProductCategories().then((response) => {
            console.log(response);
            const categoriesArray = response.data.data.map(category => (
                <option onChange={handleProductFormInputs} key={category.id} value={category.category_id} name="product_category">{category.category_name}</option>
            ));
            // Set the options state to the array of option elements
            setCategories(categoriesArray);

        });
        var data = {
            "Secret-Key": userDetails.data.secret_key
        }
        authService.cryptolabels(data).then((response) => {
            setCryptos(response.data.data);
        }).catch((e) => {
            console.log(e.response.data.message)

            swal("Oops!", e.response.data.message, "error");
        })


    }, []);

    const handleCategoryInput = (e) => {
        console.log("selected", e.target.value)
        var categoryPayload = {
            "category_id": e.target.value
        };
        authService.getProductSubCategories(categoryPayload).then((response) => {
            console.log(response);
            const subcategoriesArray = response.data.data.map(sub_category => (
                <option onChange={handleProductFormInputs} key={sub_category.id} value={sub_category.sub_category_id} name="sub_category">{sub_category.sub_category_name}</option>
            ));
            // Set the options state to the array of option elements

            setSubCategories(subcategoriesArray);
            handleProductFormInputs(e);
        });

    };

    const handleProductCreation = (e) => {
        e.preventDefault();
        setErrorProductCreationForm(validate(creationProductsInputs));
        setIsSubmit(true);
        setshowSignInModal(true);


        if (creationProductsInputs.title !== "" &&
            creationProductsInputs.sub_category !== "" &&
            creationProductsInputs.description !== "" &&
            creationProductsInputs.price !== "" &&
            creationProductsInputs.product_image !== "") {
            setFlag(true)
            let formData = new FormData();

            formData.append("product_name", creationProductsInputs.title);
            formData.append("category", creationProductsInputs.product_category);
            formData.append("sub_category_id", creationProductsInputs.sub_category);
            formData.append("search_tags", creationProductsInputs.positive_keyword);
            formData.append("product_description", creationProductsInputs.description);
            formData.append("product_price", creationProductsInputs.price);
            formData.append("product_url", creationProductsInputs.product_url);
            formData.append("product_image", productImage);
            formData.append("video_link", creationProductsInputs.video_link);
            formData.append("client_id", client_id);
            formData.append("accepted_currency", addedCryptos);

            authService.createProduct(formData).then((response) => {
                if (response.data.status) {
                    setFlag(false)
                    swal({
                        title: "Success",
                        text: "Congratulations! You have successfully Added a Product",
                        icon: "success",
                        type: 'success',
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        allowOutsideClick: false,
                    }).then((value) => {
                        if (value) {
                            navigate('/seller-dashaboard', { state: response.data });
                        }
                    });
                }
                else if (response.data.status === 400) {
                    swal("Success", response.data.message, "success");
                }
            }).catch((e) => {
                swal("Oops!", e.response.data.message, "error");
            })
        }
    }

    const canceProductCreation = (e) => {
        navigate('/seller-dashaboard');
        // window.location.reload();
    }
    const [showSelectBox, setShowSelectBox] = useState(false);

    const showCryptos = (e) => {
        e.preventDefault();
        setShowSelectBox(!showSelectBox);
    };

    const addCrypto = (e) => {
        const value = e.target.value;
        console.log("The added value" + value);
        if (!addedCryptos.includes(value)) {
            setAddedCryptos([...addedCryptos, value]);
            console.log("The added cryptos" + addedCryptos);
        }
        else {
            console.log("present")
        }
    };

    const handleRemoveCrypto = (crypto) => {
        removeCrypto(crypto);
    };

    const removeCrypto = (crypto) => {
        console.log(crypto);
        const updatedCrypto = addedCryptos.filter(value => value !== crypto);
        setAddedCryptos(updatedCrypto);
    }

    return (
        <>
            <section className='seller-dashaboard-class text-white'>
                <form onSubmit={handleProductCreation} encType='multipart/form-data'>
                    <div className='container mt-5 border-white p-5' style={{ "border": "1px solid" }}>
                        <h3 className='text-center text-uppercase mb-5'>Create a new product</h3>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <h3>PRODUCT TITLE</h3>
                                <p>Your product title is the most important place to inCLUde keywords that buyers would like to use to search for a service like yours. </p>
                            </div>
                            <div className='col-sm-1'></div>
                            <div className='col-sm-6 mt-3'>
                                <input type='text' class="form-control" name="title" onChange={handleProductFormInputs} />
                                <span className="red-error" style={{ "color": "red" }}>{errorProductCreationForm.title}  </span>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-sm-4'>
                                <h3>CATEGORY</h3>
                                <p>Choose a category and a sub-category most suitable for your product.</p>
                            </div>
                            <div className='col-sm-1'></div>
                            <div className='col-sm-6'>
                                <div className="row ">
                                    <div className='col-sm-4 mb-3'>
                                        <select class="form-select   select-arrow" aria-label="Default select example" name='product_category' id='product_category' onChange={handleCategoryInput}>
                                            <option selected disabled>Select a category</option>
                                            {categories}
                                        </select>
                                        <span className="red-error" style={{ "color": "red" }}>{errorProductCreationForm.category}  </span>
                                    </div>
                                    <div className='col-sm-4'>
                                        <select class="form-select  " aria-label="Default select example" onChange={handleProductFormInputs} name='sub_category'>
                                            <option selected disabled>Select Sub-Category</option>
                                            {subCategories}
                                        </select>
                                        <span className="red-error" style={{ "color": "red" }}>{errorProductCreationForm.sub_category}  </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-4'>
                                <h3 className=''>SEARCH TAGS</h3>
                                <p>Tag your product with buzz words that are relevant to the services you are offering. Use all 5 tags to get found.</p>
                            </div>
                            <div className='col-sm-1'></div>
                            <div className='col-sm-6'>
                                <h3 className=''>POSITIVE KEYWORD</h3>
                                <p>Enter search terms you feel buyers will use when looking for your service</p>
                                <textarea class="form-control" name="positive_keyword" onChange={handleProductFormInputs}></textarea>
                                <p className='float-end'>5 tags maximum. Use letters and numbers only</p>
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col-sm-4'>
                                <h3 className=''>PRODUCT DESCRIPTION</h3>
                                <p>describe the service and product you are offering. HIGHLIGHT ALL THE FEATURES AND BENEFITS.</p>
                            </div>
                            <div className='col-sm-1'></div>
                            <div className='col-sm-6'>
                                <textarea class="form-control" name="description" rows={10} onChange={handleProductFormInputs}></textarea>
                                <span className="red-error" style={{ "color": "red" }}>{errorProductCreationForm.description}  </span>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-4'>
                                <h3 className=''>PRODUCT PHOTO</h3>
                                <p>Add a Picture that best Describes your product</p>
                            </div>
                            <div className='col-sm-1'></div>
                            <div className='col-sm-6'>
                                <input type='file' className="form-control  " accept="image/*" name="product_image" onChange={handleImageChange} />

                                <span className="red-error" style={{ "color": "red" }}>{errorProductCreationForm.product_image}  </span>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-4'>
                                <h3>PRODUCT VIDEO LINK</h3>
                                <p>Enter a link to a video providing more information about your Product</p>
                            </div>
                            <div className='col-sm-1'></div>
                            <div className='col-sm-6'>
                                <input class="form-control  " name="video_link" onChange={handleProductFormInputs} />

                                <span className="red-error" style={{ "color": "red" }}>{errorProductCreationForm.video_link}  </span>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-4'>
                                <h3>PRICING</h3>
                                <p>Set up pricing for your product using crypto currencies</p>
                            </div>
                            <div className='col-sm-1'></div>
                            <div className='col-sm-6'>
                                <div class="row mb-2">
                                    <div className='text-white col-sm-3 col-6'>
                                        <h3>CRYPTO</h3>
                                    </div>
                                    <div className='col-sm-3 col-6'>
                                        <h3>Price</h3>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className='text-white col-sm-3 col-6'>
                                        <h5>USD PRICE</h5>
                                    </div>
                                    <div className='col-sm-3 col-6'>
                                        <input className="col-12 form-control clas-cat text-right pt-2" type='category' name='price' onChange={handleProductFormInputs} />
                                        <span className="red-error" style={{ "color": "red" }}>{errorProductCreationForm.price}  </span>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    {/* <div className='text-white col-sm-3 col-6'>
                                        <h5>BNB</h5>
                                    </div>
                                    <div className='col-sm-3 col-6'>
                                        <a href='#' className='m-auto float-end'> </a><IoMdClose class="close-icons" data-bs-dismiss="modal" />
                                    </div> */}
                                    {addedCryptos.map((crypto => (
                                        <div className="row mb-2">
                                            <div className='text-white col-sm-3 col-6'>
                                                <h5>{crypto}</h5>
                                            </div>
                                            <div className='col-sm-3 col-6' onClick={() => handleRemoveCrypto(crypto)}>
                                                <a className='m-auto float-end'> </a><IoMdClose class="close-icons" data-bs-dismiss="modal" />
                                            </div>
                                        </div>
                                    )))}
                                </div>
                                <div>
                                    <button className='col-5 btn border-white text-white mx-3' style={{ "fontSize": "18px" }} onClick={showCryptos}>Add +</button>
                                </div>
                                {showSelectBox && (
                                    <select class="col-5 border-white mx-3" style={{ "fontSize": "18px" }} aria-label="Default select example" name='wallet_crypto' onChange={addCrypto}>
                                        {cryptos.map((crypto => (
                                            <option value={crypto.crypto_label}>{crypto.crypto_label}</option>
                                        )))}
                                    </select>
                                )}
                            </div>

                        </div>

                        <div className='row mt-3'>
                            <div className='col-sm-4'>
                                <h3>PRODUCT URL</h3>
                                <p>Set up the URL for your product</p>
                            </div>
                            <div className='col-sm-1'></div>
                            <div className='col-sm-6'>
                                <input class="form-control  " name="product_url" onChange={handleProductFormInputs} />
                                <span className="red-error" style={{ "color": "red" }}>{errorProductCreationForm.product_url}  </span>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-2'> </div>
                            <div className='col-sm-10'>
                                <button className='col-4 btn border-white text-white mx-3' style={{ "fontSize": "18px" }} onClick={canceProductCreation}>cancel</button>
                                <button className='col-4 btn btn-success' style={{ "fontSize": "18px" }} type='submit'>Continue</button>
                            </div>
                        </div>
                    </div>
                </form>
            </section >
            {flag && <Loader />}
        </>
    )
}

export default CreateNewProduct 