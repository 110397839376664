import React from 'react';
import { DotLoader } from 'react-spinners';

const Loader = () => {

  return (
    <>
      <DotLoader color="#36d7b7"
        style={{
          "top": "50%",
          "left": "50%",
          "display": "inherit",
          "position": "fixed",
          "width": "60px",
          "height": "60px",
          "animation": "2s linear 0s infinite normal none running react-spinners-DotLoader-rotate"
        }} />
    </>
  );
};

export default Loader;
