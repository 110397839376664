import React, { useEffect, useState } from 'react'
import ListingHomePageHeader from "../components/ListingHomePageHeader"
import CategoryMenus from "../components/CategoryMenus";
import Categorys from "../components/Categorys";
import Listings from "../components/Listings";
import authService from '../Services/auth.service';
import swal from 'sweetalert';
import Loader from '../components/Loader';

const Home = () => {
    const [categoryData, setcategoryData] = useState([])
    const [productData, setproductData] = useState([])
    const [flag, setflag] = useState(false)

    useEffect(() => {
        setflag(true)
        authService.products().then((response) => {
            if (response.status == 200) {
                setproductData(response.data);
                setflag(false)
            } else {
                console.log("error occurred");
            }
        }).catch((e) => {
            swal("Oops!", e.response.data.message, "error");
        });

        authService.getProductCategories().then((response) => {
            setcategoryData(response.data.data)
        });

    }, [])

    const handleCategoryClick = (name) => {
        var catagorypaylod = {
            "category_name": name
        }
        setflag(true)
        authService.getProductsByCategoryApi(catagorypaylod).then((response) => {
            setproductData(response.data.data)
            setflag(false)
        });
    }


    const handleCurrency = (selectedOption) => {
        if (selectedOption !== null) {
            var currencyPaylod = {
                "accepted_currency": selectedOption?.value
            }
            authService.getProductsByCurrencyApi(currencyPaylod).then((response) => {
                if (response.status == 200) {
                    setproductData(response.data.data);
                    setflag(false)
                }
            }).catch((e) => {
                swal("Oops!", e.response.data.message, "error");
            });
        }
    }






    return (
        <>
            <div className="seller-dashaboard-class">
                <ListingHomePageHeader handleCurrency={handleCurrency} />
                <CategoryMenus categoryData={categoryData} />
                <Categorys categoryData={categoryData} handleCategoryClick={handleCategoryClick} />
                <Listings productData={productData} />
            </div>
            {flag ? <Loader /> : ""}
        </>
    )
}

export default Home