import React, { useState } from 'react'
import ListingHomePageHeader from '../components/ListingHomePageHeader'
import CategoryMenus from '../components/CategoryMenus'
import SliderCst from '../components/SliderCst';
import { BsHeart } from 'react-icons/bs';
import Select from 'react-select'
import WatchImg from '../../src/images/watch.jpg'
import LaptopImg from '../../src/images/laptop.jpg'
import PhoneImg from '../../src/images/phone.jpg'
import AirpodeImg from '../../src/images/airpodes.jpg'
import { useNavigate, useLocation } from 'react-router-dom';

const SingleProductPage = () => {

    const location = useLocation();
    const item = location.state?.item;

    return (
        <>
            <div className="seller-dashaboard-class">
                <ListingHomePageHeader />
                <CategoryMenus />
                <section>
                    <div className='container'>
                        <div className="row">
                            <div className="col-sm-6 product-images-wrapper">
                                <div className="row mt-5">
                                    <div className='col d-none d-sm-block'>
                                        <div className="thumbs-wrapper hide-for-mobile">
                                            <div className="thumb-image ">
                                                <img src={`${process.env.REACT_APP_BASE_URL}/${'productImages/' + item?.product_image}`} alt="Product Thumb Image" />
                                            </div>
                                            {/* <div className="thumb-image active">
                                                <img src={LaptopImg} alt="Product Thumb Image" />
                                            </div>
                                            <div className="thumb-image">
                                                <img src={PhoneImg} alt="Product Thumb Image" />
                                            </div>
                                            <div className="thumb-image">
                                                <img src={AirpodeImg} alt="Product Thumb Image" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-9 pb-4'>
                                        <img src={`${process.env.REACT_APP_BASE_URL}/${'productImages/' + item?.product_image}`} className='card-img-top' />
                                        {/* <SliderCst /> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 product-details-wrapper text-white mt-5">
                                <div className='row mb-5'>
                                    <div className='col-sm-6'>
                                        <select className='col-3 form-select   search-for-anything'  >
                                            <option value={item?.product_price}>USD${item?.product_price}</option>
                                            <option value={"USD$100"}>USD$100</option>
                                            <option value={"USD$200"}>USD$200</option>
                                            <option value={"USD$300"}>USD$300</option>
                                            <option value={"USD$400"}>USD$400</option>
                                            <option value={"USD$500"}>USD$500</option>
                                        </select>

                                    </div>
                                </div>
                                <h2 className="text-white">{item?.product_name}</h2>
                                <p className='cst-font-tahoma text-capitalize'>{item?.product_category} <BsHeart /></p>
                                <p className='cst-font-tahoma'>Description:</p>
                                <p className="product-description cst-font-tahoma "> {item?.product_description} </p>
                                <button className='btn btn-success col-sm-6 mt-5'>Buy Now</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default SingleProductPage