import { logDOM } from "@testing-library/react";
import axios from "axios";

//  const API_URL = "http://127.0.0.1:8000/api/";
const API_URL = "https://moonetized.com/TEST/api/";
let reqInstance;
// if (localStorage.getItem('token')) {
//     reqInstance = axios.create({ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
// } else {
//     reqInstance = axios.create({ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
// }

const userData = JSON.parse(localStorage.getItem('userData'))
const secret_key = userData?.data?.secret_key;
reqInstance = axios.create({ headers: { 'Secret-Key': secret_key } });


const userSignUp = (data) => {
    return reqInstance.post(API_URL + "signup", data,)
}

const userLogin = (data) => {
    return reqInstance.post(API_URL + "login", data,)
}

const userDetails = (data) => {
    return reqInstance.post(API_URL + "userdetails", data,)
}

const getUserProducts = (data) => {
    return reqInstance.get(API_URL + "userProducts", data,)
}

const createProduct = (data) => {
    return reqInstance.post(API_URL + "createProduct", data,)
}

const editProductApi = (data) => {
    return reqInstance.post(API_URL + `editProduct`, data,)
}

const getProductCategories = (data) => {
    return axios.get(API_URL + "productCategories", data,)
}

const getProductSubCategories = (data) => {
    return reqInstance.post(API_URL + "productSubCategories", data,)
}
const getPriceConversion = (data) => {
    return reqInstance.post(API_URL + "getPriceConversion", data,)
}

const generateSecretKey = (data) => {
    return reqInstance.post(API_URL + "generateSecretKey", data,)
}

const editProfile = (data) => {
    return reqInstance.post(API_URL + "editProfile", data,)
}

const getCountries = (data) => {
    return reqInstance.get(API_URL + "countries", data,)
}

const addCrypto = (data) => {
    return reqInstance.post(API_URL + "addCrypto", data,)
}

const uploadProfilePicture = (data) => {
    return reqInstance.post(API_URL + "uploadProfilePicture", data,)
}

const getCryptoLabels = (data) => {
    return reqInstance.post(API_URL + "getCryptoLabels", data,)
}

const userWallets = (data) => {
    return reqInstance.get(API_URL + "userWallets", data,)
}

const saveWallet = (data) => {
    return reqInstance.post(API_URL + "saveWallet", data,)
}

const products = (data) => {
    return reqInstance.get(API_URL + "products", data,)
}

const cryptolabels = (data) => {
    return reqInstance.get(API_URL + "cryptolabels", data,)
}

const getSingleProductId = (data) => {
    return reqInstance.post(API_URL + "getProductByID", data,)
}

const deleteProduct = (data) => {
    return reqInstance.post(API_URL + "deleteProduct", data,)
}

const getProductsByCategoryApi = (data) => {
    return reqInstance.post(API_URL + "getProductsByCategory", data,)
}

const getProductsByCurrencyApi = (data) => {
    return reqInstance.post(API_URL + "getProductsByCurrency", data,)
}


export default {
    userLogin,
    userSignUp,
    userDetails,
    getUserProducts,
    createProduct,
    editProductApi,
    getProductCategories,
    getProductSubCategories,
    getPriceConversion,
    generateSecretKey,
    editProfile,
    getCountries,
    addCrypto,
    uploadProfilePicture,
    getCryptoLabels,
    userWallets,
    saveWallet,
    products,
    cryptolabels,
    getSingleProductId,
    deleteProduct,
    getProductsByCategoryApi,
    getProductsByCurrencyApi
}