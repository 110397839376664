import React from 'react'
import { CiFacebook } from 'react-icons/ci'
import { FcGoogle } from "react-icons/fc";
import { AiFillApple } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useState, useEffect } from 'react';
import authService from '../Services/auth.service';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
//import { useNavigate } from 'react-router';


const SignIn = () => {
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const [showSignInModal, setshowSignInModal] = useState(true);
    const [loginFormInputs, setloginFormInputs] = useState({
        userNameOrEmail: "",
        password: ""
    })

    const [errorLoginFormInputs, setErrorLoginFormInputs] = useState({
        userNameOrEmail: "",
        password: ""
    })

    const handleLoginFormInputs = (e) => {
        const { name, value } = e.target
        setloginFormInputs({ ...loginFormInputs, [name]: value })
        switch (name) {
            case 'userNameOrEmail':
                errorLoginFormInputs.userNameOrEmail = value.length > 0 ? "" : "Enter username or email"
                break;
            case 'password':
                errorLoginFormInputs.password = value.length > 0 ? "" : "Enter password"
                break;
            default:
                break;
        }
        setErrorLoginFormInputs(errorLoginFormInputs)
    }

    const validate = (value) => {
        if (!loginFormInputs.userNameOrEmail) {
            errorLoginFormInputs.userNameOrEmail = "Enter username or email";
        }
        if (!loginFormInputs.password) {
            errorLoginFormInputs.password = "Enter password";
        }
        return errorLoginFormInputs;
    };

    useEffect(() => {
        if (Object.keys(errorLoginFormInputs).length === 0 && isSubmit) {
        }
    }, []);

    const handleSignInButtonSubmitForm = (e) => {
        e.preventDefault();
        setErrorLoginFormInputs(validate(loginFormInputs));
        setIsSubmit(true);

        if (loginFormInputs.userNameOrEmail !== "" && loginFormInputs.password !== "") {


            var loginPayload = {
                username: loginFormInputs.userNameOrEmail,
                password: loginFormInputs.password,
            }
            console.log(loginPayload);
            authService.userLogin(loginPayload).then((response) => {

                if (response.data.status == "success") {
                    setshowSignInModal(false);
                    localStorage.setItem('userData', JSON.stringify(response.data))
                    navigate('/seller-dashaboard', { state: response.data });
                    window.location.reload();

                }
                else {
                    console.log("failed login")
                    // swal("Success", response.data.message, "success");
                }
            }).catch((e) => {
                alert("Wrong credentials, please try again");
                swal("Oops!", e.response.data.message, "error");
            })
        }
    }

    return (
        <>
            {showSignInModal && (
                <div className="modal fade " id="signinModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content cst-bk">
                            <div className="modal-header">
                                <a href='#' className='m-auto float-end'> </a><IoMdClose className='close-icons' data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body mx-3">
                                <h3 className='text-center mb-5 text-uppercase'> sign in moonetized</h3>
                                {/* <div className='row p-2 px-3 ' >
                                <button className='btb btn-facebook-btn p-2'><CiFacebook className='mx-3' />Continue with Facebook</button>
                            </div>
                            <div className='row p-2 px-3 ' >
                                <button className='btb btn-facebook-btn p-2'><FcGoogle className='mx-3' />Continue with Google</button>
                            </div>
                            <div className='row p-2 px-3 ' >
                                <button className='btb btn-apple-btn p-2'><AiFillApple className='mx-3' />Continue with Apple</button>
                            </div> */}

                                {/* <div class="d-flex justify-content-between align-items-center">
                                <hr class="w-50" />
                                <span class="mx-3">OR</span>
                                <hr class="w-50" />
                            </div> */}
                                <form onSubmit={handleSignInButtonSubmitForm}>
                                    <div className='row p-2 px-3 ' >
                                        <input className="col-12 form-control clas-cat text-right pt-2" name='userNameOrEmail' placeholder='Email/Username' onChange={handleLoginFormInputs} />
                                    </div>
                                    <span className="red-error" style={{ "color": "red" }}> {errorLoginFormInputs.userNameOrEmail}</span>
                                    <div className='row p-2 px-3 ' >
                                        <input class="col-12 form-control clas-cat text-right pt-2" type="password" name='password' placeholder='Password' onChange={handleLoginFormInputs} />
                                    </div>
                                    <span className="red-error" style={{ "color": "red" }}> {errorLoginFormInputs.password}</span>
                                    <div className='row mt-4 mb-2 p-3' >
                                        <button type='submit' className='btn btn-green-cst p-2'>Continue</button>
                                    </div>
                                </form>
                                <div className='row mb-5' >
                                    <div className='col'>
                                        <input className='' type="checkbox" name="" id="" />
                                        <span className='mx-2'>Remember me</span>
                                    </div>
                                    <div className='col'>
                                        <a href='#' className='forgot-password-a'>Forgot Password?</a>
                                    </div>
                                </div>

                                <hr className='mt-5' />
                                <p className='n-remember-yet text-center'>Not Remember yet? <a href='#' className='join-now-a text-decoration-underline' data-bs-toggle="modal" data-bs-target="#joinNowModal" data-bs-dismiss="modal" aria-label="Close">Join Now</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default SignIn