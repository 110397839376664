import React, { useRef, useState, useEffect } from 'react'
import { IoMdClose } from "react-icons/io";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { MdSave } from 'react-icons/md';
import authService from '../Services/auth.service';
import swal from 'sweetalert';
import Select from 'react-select';


const customStyles = {
    input: (provided) => ({
        ...provided,
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 16
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#2C133B',
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 16
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 16
    }), menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#5C245C',
        fontFamily: 'Roboto',
        fontSize: 16
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: 'white',
        fontSize: 16
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#B186D2' : '#3A1740',
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 15
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition, color: 'white' };
    }
};


const EditProfileSetting = () => {
    const [showPassword, setShowPassword] = useState(false);
    const userDetails = JSON.parse(localStorage.getItem('userData'));
    const [secretKey, setSecretKey] = useState("");
    const [password, setPassword] = useState(userDetails?.data?.password);
    const [email, setEmail] = useState(userDetails?.data?.email);
    const [WalletAddress, setWalletAddress] = useState('');
    //const [newWalletAddress, setNewWalletAddress] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [cryptoLabels, setCryptoLabels] = useState([]);

    const walletsOptions = cryptoLabels.map((iteam) => {
        return { value: iteam, label: iteam }
    })

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        // setSecretKey(userData);
        setprofileEditInputs({ ...profileEditInputs, "secret_key": userData?.data?.secret_key })

    }, [])


    const handleInputChange = event => {
        setWalletAddress(event.target.value);
        // Check if select box has been changed
        if (selectValue == '') {
            swal('Please select a crypto type first');
        }

    };

    // const handleSelectChange = (event) => {
    //     const value = event.target.value;
    //     setSelectValue(value);
    //     var data = {
    //         clientID: userDetails.data.clientID
    //     }
    //     authService.userWallets(data).then((response) => {
    //         console.log(response.data.data);
    //         const wallet = response.data.data;
    //         let foundMatch = false;

    //         wallet.forEach(element => {
    //             if (element.label === value) {
    //                 console.log("Relevant address: " + element.wallet + " " + element.label);
    //                 setWalletAddress(element.wallet);
    //                 foundMatch = true;
    //                 handleInputChange();
    //             }

    //         });

    //         if (foundMatch == false) {
    //             setWalletAddress('');
    //         }

    //     }).catch((e) => {
    //         console.log(e.response.data.message)
    //         swal("Oops!", e.response.data.message, "error");
    //     });

    // };

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    useEffect(() => {
        // Get the crypto labels available
        authService.getCryptoLabels().then((response) => {
            setCryptoLabels(response.data.data);
        });
    }, []);

    const GenerateSecretKey = (e) => {
        e.preventDefault();
        var data = {
            clientID: userDetails?.data?.clientID
        };
        swal({
            title: "Confirmation",
            text: "If You Generate a New Key, All Your Old Keys Will Be Ruined. Click Proceed If You are Sure, or Cancel.",
            icon: "warning",
            buttons: ["Cancel", "Proceed"],
        }).then((isConfirmed) => {
            if (isConfirmed) {

                authService.generateSecretKey(data).then((response) => {
                    setSecretKey(response.data.data.SecretKey)
                    // localStorage.setItem('SecretKey', JSON.stringify(response.data.data["Secret-Key"]))
                    setprofileEditInputs({ ...profileEditInputs, "secret_key": response.data.data["Secret-Key"] })

                    const userDataString = localStorage.getItem("userData");
                    const userData = JSON.parse(userDataString);
                    userData.data.secret_key = response.data.data["Secret-Key"];
                    const updatedUserDataString = JSON.stringify(userData.data);
                    localStorage.setItem("userData", updatedUserDataString);
                }).catch((error) => {
                    console.log("Error:", error);
                });
            }
        })
    };

    const userWallets = (e) => {
        var data = {
            clientID: userDetails?.data?.clientID
        }
        authService.userWallets(data).then((response) => {
            console.log(response.data.data);
        }).catch((e) => {
            console.log(e.response.data.message)
            swal("Oops!", e.response.data.message, "error");
        });
    }

    const [profileEditInputs, setprofileEditInputs] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        wallet_crypto: "",
        wallet_address: "",
        secret_key: ""
    })

    const handleProfileEditInputs = (e) => {
        const { name, value } = e.target
        setprofileEditInputs({ ...profileEditInputs, [name]: value })
    }


    const editProfile = (e) => {
        e.preventDefault();
        if (profileEditInputs.password == profileEditInputs.confirmPassword) {
            const secret = secretKey;
            var data = {
                clientID: userDetails?.data?.clientID,
                email: profileEditInputs.email,
                password: profileEditInputs.password,
                WalletAddress: WalletAddress
            }

            authService.editProfile(data).then((response) => {
                swal({
                    title: "Success",
                    text: "Profile Updated Successfully",
                    icon: "success",
                })
            }).catch((e) => {
                console.log(e.response.data.message)
                swal("Oops!", e.response.data.message, "error");
            });
        }
    }

    const [errorRegistrationForm, seterrorRegistrationFom] = useState({
        password: "",
        confirmPassword: ""
    })

    const handleRegsitartionFormInputes = (e) => {
        const { name, value } = e.target
        setprofileEditInputs({ ...profileEditInputs, [name]: value })
        switch (name) {
            case 'password':
                if (value.length < 8) {
                    errorRegistrationForm.password = "Password length must more than 8 character";
                }
                else if (value.length > 255) {
                    errorRegistrationForm.password = "Password length must be less than 255 characters";
                }
                else if (value !== profileEditInputs.confirmPassword) {
                    errorRegistrationForm.password = "Password and Confirm Password does not match.";
                }
                else {
                    errorRegistrationForm.password = ""
                    errorRegistrationForm.confirmPassword = "";
                }
                break;
            case 'confirmPassword':
                if (!value) {
                    errorRegistrationForm.confirmPassword = "Please enter Confirm Password ";
                }
                else if (value.length < 8) {
                    errorRegistrationForm.confirmPassword = "Password length must more than 8 character";

                }
                else if (value.length > 255) {
                    errorRegistrationForm.confirmPassword = "Password length must be less than 255 characters";
                }
                else if (value !== profileEditInputs.password) {
                    errorRegistrationForm.confirmPassword = "Password and Confirm Password does not match.";
                }
                else {
                    errorRegistrationForm.confirmPassword = ""
                    errorRegistrationForm.password = "";
                }
                break;
        }
        seterrorRegistrationFom(errorRegistrationForm)

    }

    const saveWallet = (e) => {
        const data = {
            clientID: userDetails?.data?.clientID,
            crypto_label: profileEditInputs.wallet_crypto,
            wallet_address: WalletAddress
        }
        console.log(profileEditInputs, "profileEditInputs");
        console.log(data, "data");
        swal({
            title: "Confirmation",
            text: "Your Are Saving A   " + selectValue + "  Wallet. With a wallet address  " + WalletAddress,
            icon: "warning",
            buttons: ["Cancel", "Proceed"],
        }).then((isConfirmed) => {
            if (isConfirmed) {
                authService.saveWallet(data).then((response) => {
                    console.log(response);
                }).catch((error) => {
                    console.log("Error:", error);
                });

            }
        });

    }

    const handleWallets = (selectedOption) => {
        if (selectedOption !== null) {
            setprofileEditInputs({ ...profileEditInputs, wallet_crypto: selectedOption.value })
            setSelectValue(selectedOption.value)
        }

        var data = {
            clientID: userDetails?.data?.clientID
        }

        authService.userWallets(data).then((response) => {
            const wallet = response.data.data;
            let foundMatch = false;

            wallet.forEach(element => {
                if (element.label === selectedOption.value) {
                    console.log("Relevant address: " + element.wallet + " " + element.label);
                    setWalletAddress(element.wallet);
                    foundMatch = true;
                    handleInputChange();
                }
            });

            if (foundMatch == false) {
                setWalletAddress('');
            }
        }).catch((e) => {
            console.log(e.response.data.message)
            swal("Oops!", e.response.data.message, "error");
        });
    };

    return (
        <>
            <div className="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content cst-bk">
                        <div className="modal-header">
                            <a href='#' className='m-auto float-end'> </a><IoMdClose class="close-icons" data-bs-dismiss="modal" />
                        </div>
                        <div className="modal-body pb-2">
                            <h3 className='text-white text-center mb-5'>Edit profile settings</h3>
                            <form onSubmit={editProfile}>
                                <div className='row pb-2'>
                                    <div className="input-container">
                                        <div className='row px-2 pb-2 input-container'>
                                            <input
                                                name='email'
                                                class="col-12 form-control clas-cat text-right"
                                                type='email' placeholder={email}
                                                style={{ textAlign: 'right', paddingRight: "10px" }}
                                                onChange={handleProfileEditInputs}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row pb-2'>
                                    <div className="input-container">
                                        <div className='row px-2 pb-2'>
                                            <input
                                                name='password'
                                                class="col-12 form-control clas-cat text-right pt-2"
                                                type="password"
                                                placeholder='Password'
                                                onChange={handleRegsitartionFormInputes}
                                            />
                                        </div>
                                        <span className="red-error pb-2" style={{ "color": "red" }}>{errorRegistrationForm.password}  </span>
                                        <div className='row px-2' >
                                            <input
                                                name='confirmPassword'
                                                class="col-12 form-control clas-cat text-right pt-2"
                                                type="password"
                                                placeholder='ConfirmPassword'
                                                onChange={handleRegsitartionFormInputes}
                                            />
                                        </div>
                                        <span className="red-error" style={{ "color": "red" }}>{errorRegistrationForm.confirmPassword}  </span>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-1 p-1' >
                                    <button className='btn btn-green-cst mb-5' type='submit'>Save</button>
                                </div>
                                <div className='row '>
                                    <div className="input-container">
                                        <input
                                            name='secret_key'
                                            className="col-12 form-control clas-cat text-right"
                                            type={showPassword ? "text" : "password"}
                                            value={profileEditInputs.secret_key}
                                            style={{ textAlign: 'right', paddingRight: "34px" }}
                                            onChange={handleProfileEditInputs}
                                        />
                                        <span className="icon-container" onClick={togglePasswordVisibility}>
                                            {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                                        </span>
                                    </div>
                                </div>
                            </form>
                            <div className='row p-2  ' >
                                <button class="btn btn-new-key-genrate" onClick={GenerateSecretKey}>Generate New secret Key</button>
                            </div>

                            <div>
                                <h2 style={{ textAlign: 'center' }}>Add Wallet</h2>
                                <div className='row pt-2' >
                                    <div className='col-12 cst-select'>
                                        {/* <select class="form-select   select-arrow" aria-label="Default select example" name='wallet_crypto' onChange={handleSelectChange}>
                                            <option disabled selected>Select Crypto of the Wallet</option>
                                            {cryptoLabels.map((option, index) => (
                                                <option key={index} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select> */}
                                        <Select
                                            name='wallet_crypto'
                                            className="multiselect-cst"
                                            options={walletsOptions}
                                            placeholder="Select"
                                            styles={customStyles}
                                            onChange={handleWallets}
                                            isClearable={true}
                                        />
                                    </div>
                                </div>
                                <div className='row pt-2 mb-4'>
                                    <div className="input-container">
                                        <input className="col-12 form-control clas-cat text-right" type="text" name='wallet_address' value={WalletAddress} style={{ textAlign: 'right', paddingRight: "34px" }} onChange={handleInputChange} />
                                        {WalletAddress ? (
                                            <span className="icon-container" onClick={saveWallet}><MdSave /></span>
                                        ) : (
                                            <span className="icon-container">
                                                <MdEdit />
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditProfileSetting