import React from 'react'
import { useState, useEffect } from 'react';
import Header from '../components/Header'
import SheetImg from '../images/sheetImg.png'
import { BsThreeDots } from 'react-icons/bs';
import { AiFillPlusCircle } from 'react-icons/ai';
import ProfileSideBar from '../components/ProfileSideBar';
import { useNavigate, useLocation } from 'react-router-dom';
import authService from '../Services/auth.service';
import swal from 'sweetalert';
import { BsPlusCircle } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Loader from '../components/Loader';


const SellerDashabord = () => {
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem('userData'));

    const [deleteFlag, setdeleteFlag] = useState("")
    const [flag, setFlag] = useState(false)
    const [userProducts, setUserProducts] = useState([]);

    const handleNavigation = () => {
        navigate("/add-new-product")
    }

    const handleDeleteButton = (id) => {
        setdeleteFlag(id)
    }

    const handleDeleteConfitmation = () => {
        setFlag(true)
        if (deleteFlag) {
            const deletePaylod = {
                "product_id": deleteFlag
            }
            authService.deleteProduct(deletePaylod).then((response) => {
                if (response.status == 200) {
                    setFlag(false)
                    swal("Success!", response.data.data, "success");
                }
            }).catch((e) => {
                console.error(e);
                swal("Oops!", e.response.data.message, "error");
            });
        }
    }

    useEffect(() => {
        const userPayload = { client_id: userDetails?.data?.clientID.toString() };
        authService.getUserProducts(userPayload).then((response) => {
            if (response.status == 200) {
                setUserProducts(response.data.data);
            } else {
                console.log("failed login");
            }
        }).catch((e) => {
            console.error(e);
            swal("Oops!", e.response.data.message, "error");
        });
    }, [flag]);

    return (
        <>
            <div className='seller-dashaboard-class'>
                <Header />
                <section className='mt-5'>
                    <div className='container'>
                        <div className="container-fluid">
                            <div className="row">
                                <ProfileSideBar />  {/* Profile Componenet */}
                                <div className="col-md-7 col-lg-8 col-xl-8 ">
                                    <div className="card mb-4 cst-border-white p-3">
                                        <div className="row">
                                            <div className="col-md-2 col-6">
                                                <h3 className="dashboard-heading4 pt-2 text-decoration-underline">Product Listed</h3>
                                            </div>
                                            <div className="col-md-7 col-6">
                                                <h3 className="dashboard-heading4 pt-2">Draft</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-5 mt-5">
                                        <div className="row">
                                            {userProducts?.map((item, index) => {
                                                return <>
                                                    <div className="col-xl-4 col-md-6 mb-4" key={index}>
                                                        <div className="card" >
                                                            <img src={`${process.env.REACT_APP_BASE_URL}/${'productImages/' + item.product_image}`} className='card-img-top' style={{ maxHeight: "248px", minHeight: "248px" }} />
                                                            <div className="card-body" style={{ "background": "#124D40" }}>
                                                                <h5 className="card-title text-white cst-font-tahoma">{item.product_name}</h5>
                                                                <div className='text-white justify-content-between d-flex'>
                                                                    <div className='mx-2' style={{ fontSize: "22px" }}>
                                                                        <div class="dropdown">
                                                                            <BsThreeDots className="dropdown-toggle cst-cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false" />
                                                                            <ul className="dropdown-menu">
                                                                                <li><a className="dropdown-item cst-cursor-pointer" onClick={() => navigate("/add-new-product")}><BsPlusCircle /> Add</a></li>
                                                                                <li><a className="dropdown-item cst-cursor-pointer" onClick={() => navigate(`/edit-product/${item.product_id}`)}><FaRegEdit /> Edit</a></li>
                                                                                <li><a className="dropdown-item cst-cursor-pointer" href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => handleDeleteButton(item.product_id)}><RiDeleteBin6Line /> Delete</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className='text-white mx-2 cst-font-tahoma text-uppercase'>Starting at {item.product_price}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            })}

                                            <div className="col-xl-4 col-md-6 mb-4 ">
                                                <div className="card pt-5 pb-4" style={{ "background": "#124D40", "align-items": "center" }}  >
                                                    <AiFillPlusCircle className='add-product-cst' style={{ "font-size": "100px", "color": "white" }} onClick={handleNavigation} />
                                                    <div className="card-body" >
                                                        <h5 className="card-title text-center text-white">CREATE  A NEW PRODUCT</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >

            {/* <!-- Modal --> */}
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setdeleteFlag(false)}></button>
                        </div>
                        <h1 class="text-center " id="staticBackdropLabel">Delete Product</h1>
                        <div class="modal-body">
                            <div className='d-flex justify-content-evenly'>
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={() => setdeleteFlag(false)}
                                    style={{
                                        "border-radius": "10px",
                                        "box-shadow": "0 4px 4px rgba(0,0,0,.25)",
                                        "color": "#fff",
                                        "font-size": "25px",
                                        "font-weight": "300",
                                        "height": "59px",
                                        "line-height": "36px",
                                        "max-width": "175px",
                                        "width": "100%"
                                    }}>No</button>
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={handleDeleteConfitmation}
                                    style={{
                                        "border-radius": "10px",
                                        "box-shadow": "0 4px 4px rgba(0,0,0,.25)",
                                        "color": "#fff",
                                        "font-size": "25px",
                                        "font-weight": "300",
                                        "height": "59px",
                                        "line-height": "36px",
                                        "max-width": "175px",
                                        "width": "100%"
                                    }}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {flag && <Loader />}
        </>
    )
}

export default SellerDashabord