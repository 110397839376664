import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { BsPlusCircle, BsSearch, BsThreeDots } from 'react-icons/bs';
import { FaRegEdit, FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'
import Select from 'react-select';
import Logo from "../assets/img/main_logo.svg"
import { FiSettings } from 'react-icons/fi';

const options = [
    { value: 'BTC', label: 'BTC' },
    { value: 'ETH', label: 'ETH' },
    { value: 'USDT', label: 'USDT' },
    { value: 'BNB', label: 'BNB' },
]

const customStyles = {
    input: (provided) => ({
        ...provided,
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 16,
        paddingRight: 150,

    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#2C133B',
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 16,
        borderRadius: "16px",
        width: "200px"

    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 16
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: '#5C245C',
        fontFamily: 'Roboto',
        fontSize: 16
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: 'white',
        fontSize: 16
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#B186D2' : '#3A1740',
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: 15,

    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition, color: 'white', };
    }
};


const ListingHomePageHeader = (props) => {
    const navigate = useNavigate()

    return (
        <>
            <header className="nav_wrapper  pt-3">
                <div className="container">
                    <div className="nav_inner">
                        <nav className="navbar navbar-expand-lg bg-body-tertiary">
                            <div className="container-fluid">
                                <div className="main_logo">
                                    <img src={Logo} alt="logo" onClick={() => navigate("/home")} />
                                </div>
                                <ul className='mx-5 d-flex'>
                                    <li className="cst-class-m">
                                        <span className='text-white preferred-c'>Preferred Currency:</span>
                                    </li>
                                    <li class="cst-class-m">
                                        <Select
                                            class="multiselect-cst search-for-anything"
                                            placeholder="Select"
                                            options={options}
                                            styles={customStyles}
                                            onChange={props.handleCurrency}
                                            isClearable={true}
                                        // isMulti
                                        />
                                    </li>
                                    <li class="cst-class-m">
                                        <div className="input-container">
                                            <input className="search-for-anything " type="text" placeholder='Search for anything' />
                                            <span className="icon-container" >
                                                <BsSearch style={{ "color": "white" }} />
                                            </span>
                                        </div>
                                    </li>
                                    <li className="cst-class-m">
                                        <div class="dropdown">
                                            <FaUserCircle className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{ "color": "white", fontSize: "24px", marginLeft: "20px" }} />
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#joinNowModal" data-bs-dismiss="modal" ><BsPlusCircle /> Join Moonetized</a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" ><FiSettings /> Setting</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="cst-class-m ">
                                        <AiOutlineShoppingCart style={{ "color": "white", fontSize: "24px", marginLeft: "20px" }} />
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header >
        </>
    )
}

export default ListingHomePageHeader