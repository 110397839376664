
import React, { useContext } from 'react'
import { AiOutlineEye } from "react-icons/ai";
import { MdLocationOn, MdModeEditOutline } from 'react-icons/md';
import { FaUserAlt } from 'react-icons/fa';
import { MdCreditScore } from 'react-icons/md';
import plus from '../images/plus.png';
import { useState, useEffect, useRef } from 'react';
import authService from '../Services/auth.service';
import { RxDotFilled } from 'react-icons/rx';
import { useNavigate } from 'react-router';

const ProfileSideBar = () => {
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem('userData'));

    useEffect(() => {
        if (userDetails === null) {
            navigate("/")
        }
    }, [])

    const clientID = userDetails?.data?.clientID;
    const profilePhoto = 'profileImages/' + userDetails?.data?.profilePhoto;

    const date = new Date(userDetails?.data?.start_date);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const start_date = date.toLocaleDateString('en-US', options);

    //Picture Change
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('clientID', clientID);
        authService.uploadProfilePicture(formData).then((response) => {
        }).catch((error) => {
            console.log("Error:", error.response);
        });
    };

    const handleCurrentPicture = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <div class="d-md-block col-md-5 col-lg-4 col-xl-4">
                <div class="card mb-4 p-4 cst-border-white">
                    <div class="pt-3">
                        <ul class="navbar-nav">
                            <div className='d-flex justify-content-end row'>
                                <button className='btn btn-success-outline text-white online-button-seller-dashbord float-right col-3'><RxDotFilled className='green-dot' />Online</button>
                            </div>
                            <div className='text-center mb-4'>
                                {/* <img src={ProfileLogo} className='rounded-circle'/> */}
                                {selectedFile ? <img name='image' class="rounded-circle shadow-4-strong" src={URL.createObjectURL(selectedFile)} alt="Selected File" onClick={handleCurrentPicture} />
                                    : <>
                                        <img
                                            class="rounded-circle shadow-4-strong"
                                            alt={profilePhoto}
                                            src={profilePhoto && profilePhoto == "profileImages/null" ? plus : process.env.REACT_APP_BASE_URL + '/' + profilePhoto}
                                            onClick={handleCurrentPicture} />
                                    </>
                                }

                                <input name="image" type="file" accept="image/*" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
                                <h4 className='mt-3 text-white text-uppercase'>@{userDetails?.data?.username}</h4>

                            </div>
                            <button type="button" class="btn border-white text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <AiOutlineEye />&nbsp; &nbsp; &nbsp;&nbsp;
                                Edit Profile Settings
                            </button>
                            <hr style={{ "color": "white" }} />
                            <div className='container'>
                                <div className='text-white justify-content-between d-flex'>
                                    <div className='text-white cst-font-tahoma'><MdLocationOn className='from-icon-cst' /> From</div>
                                    <div className='text-white cst-font-tahoma'>{userDetails?.data?.location}</div>
                                </div>

                                <div className='text-white justify-content-between d-flex'>
                                    <div className='cst-font-tahoma'><FaUserAlt className='member-since-icon-cst' /> Member Since</div>
                                    <div className='text-white cst-font-tahoma'>{start_date}</div>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
                <div class="card mb-4 p-4 cst-border-white">
                    <div class="pt-3">
                        <ul class="navbar-nav">
                            <div className='container'>
                                <div className='text-white justify-content-between d-flex'>
                                    <div className='text-white cst-font-tahoma'><MdCreditScore className='from-icon-cst' /> Client Credit</div>
                                    <div className='text-white cst-font-tahoma'>{userDetails?.data?.credits == null ? "-" : userDetails?.data?.credits}</div>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileSideBar