import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <>
            <section class="page_404">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="four_zero_four_bg">
                                <h1 class="text-center mb-5">404</h1>
                            </div>
                            <div class="contant_box_404 mt-3">
                                <h1  >
                                    Look like you're lost
                                </h1>
                                <h1 className='mb-5'>the page you are looking for page that not available!</h1>
                                <Link to={"/"} class="link_404">Go to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PageNotFound