import React, { useEffect, useState } from 'react'
import authService from '../Services/auth.service';

const Categorys = (props) => {

 
    return (
        <>
            <section className='mt-5 mb-5'>
                <div className="container ">
                    {/* <div className="d-flex justify-content-between justify-content-xl-evenly"> */}
                    <div className="d-flex justify-content-space-between ">
                        {props.categoryData?.map((iteam, index) => {
                            return <>
                                <div key={index} className="d-flex flex-column align-items-center px-3 cst-cursor-pointer" onClick={() => props.handleCategoryClick(iteam.category_name)}>
                                    <img src="https://www.w3schools.com/howto/img_avatar.png" className="round-images-csr img-fluid mb-3" />
                                    <span className="text-white text-capitalize">{iteam.category_name}</span>
                                </div>
                            </>
                        })}
                        {/* <div className="d-flex flex-column align-items-center">
                            <img src="https://www.w3schools.com/howto/img_avatar.png" className="round-images-csr img-fluid mb-3" />
                            <span className="text-white">Category 1</span>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <img src="https://www.w3schools.com/howto/img_avatar.png" className="round-images-csr img-fluid mb-3" />
                            <span className="text-white">Category 2</span>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Categorys